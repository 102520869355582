import React, { useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import ModalPago from "../components/ModalPago";

const NavLink = (props) => {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return <Link {...props} className={match ? "active" : "non-active"} />;
};
const BecomeDelegate = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      {showModal && (
        <ModalPago showModal={showModal} setShowModal={setShowModal} />
      )}
      {/* HERO   */}
      <div className="bg-convierte-delegado">
        <div id="hero" className="bienvenido-al-club herroo">
          <div className="container p-10">
            <h1 style={{ color: "#BB3631" }}>SE PARTE DEL CLUB</h1>
            <p>
              ¡Recuerda! Antes de comprar tu TOKEN debes crear tu CryptoWallet.{" "}
            </p>
            <div className="delegados_images">
              <ul>
                <li>
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/ananda-hotel.appspot.com/o/nfts%2F80.png?alt=media&token=42f4779d-e0a4-4e8e-a885-0d05597d2e4c"
                    alt="nft1"
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/ananda-hotel.appspot.com/o/nfts%2F81.png?alt=media&token=2ef718aa-8e41-4192-b5ad-2e557a0d82c5"
                    alt="nft3"
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/ananda-hotel.appspot.com/o/nfts%2F87.png?alt=media&token=ab444145-3ac5-472e-a3d4-2e4b7ad947f6"
                    alt="nft4"
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/ananda-hotel.appspot.com/o/nfts%2F89.png?alt=media&token=5da87316-f8ba-444b-bb30-803844ec3780"
                    alt="nft5"
                  />
                </li>
              </ul>
            </div>
            <button className="ppal_cta" onClick={() => setShowModal(true)}>
              ¡Comprar token!
            </button>
          </div>
        </div>
        {/* END HERO   */}

        <div id="yasanescomo">
          <div className="container">
            <h2 style={{ color: "#BB3631" }}>¿Ya sabes cómo hacerlo?</h2>
            <h4>¡Nosotros te enseñamos en 90 segundos!</h4>

            <p>
              A continuación, encontrarás un video tutorial de como crear tu
              billetera digital (CryptoWallet) y cómo compartir su dirección” de
              destino para recibir activos digitales (TOKENS) en la red de
              Polygon.
            </p>

            <video
              poster="https://firebasestorage.googleapis.com/v0/b/nft-collection-e23b5.appspot.com/o/Nuevo%20Dise%C3%B1o%2Fabout_order-nft.webp?alt=media&token=75fdfabd-826f-4508-bf56-9dade48811c8"
              controls
              loop
              disablePictureInPicture
              controlsList="nodownload"
            >
              <source src="https://firebasestorage.googleapis.com/v0/b/nft-collection-e23b5.appspot.com/o/Nuevo%20Dise%C3%B1o%2Fvideos%2FABRIL-WALLET%20FINAL.mp4?alt=media&token=33b91cb9-4722-4d7f-985a-af688dfd51b8" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeDelegate;
