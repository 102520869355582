import React from "react";
import { useNFTsByOwner } from "../../hooks/useContractData";
import { useParams } from "react-router-dom";
import { NavLinkk } from "./NavLink";

function CardsNfts({ nfts }) {
  const { collectionId } = useParams();
  const tokenId = (tokenId) => {
    const id = tokenId.split("");
    id.reverse();

    let letMap = 0;
    id.map((item, index) =>
      item !== "0" && item !== "x" ? (letMap = index) : ""
    );
    //console.log(letMap)
    const arrId = id.slice(0, letMap + 1);

    const temp = arrId.reverse().toString().replace(/,/g, "");

    return parseInt(temp, 16);
  };

  return (
    <>
      <div className="delegados_images">
        <ul>
          {nfts &&
            nfts.map((nft) => (
              <li key={nft.tokenId}>
                <img
                  className="img-fluid"
                  src={nft.image.cachedUrl}
                  alt={nft.tokenId}
                />
                <div className="nft-name mt-3">TUS TOKENS</div>
                <span>
                  <p  style={{color:'#BB3631'}} className="d-flex gap-2 justify-content-center">
                    <span >Número de token</span > {tokenId(nft.tokenId)}
                  </p>
                </span>
                {/* to={`/collecion-club-detail/${collectionId}/${tokenId(
                    nft.id.tokenId
                  )}`} */}
                <NavLinkk
                  to={`/collecion-club-detail/${collectionId}/${tokenId(
                    nft.tokenId
                  )}`}
                >
                  <span
                    href="#"
                    className="ppal_cta"
                    style={{ fontSize: "23px" }}
                  >
                    CONECTAR TOKEN 
                  </span>
                </NavLinkk>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}

export function Collection({ collection }) {
  const contractId = collection;
  const { nftsAccount, nftsAccLoading } = useNFTsByOwner({ contractId });


  if (nftsAccount.length < 1) {
    return (
      <div className="">
        <div style={{marginTop:'20px'}}>
          {nftsAccLoading ? (
            <p>Cargando....</p>
          ) : (
            <p>
              ¡Actualmente no cuentas con ningun token en nuestras colecciones!!
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {/* {console.log(nftsAccount)} */}
      {nftsAccount && (
        <>
          {/* <CardsNfts nfts={NFTS} /> */}
          <CardsNfts nfts={nftsAccount} />
          {/* {nftsAccount && <Tabs nfts={nftsAccount} contract={contractId} />} */}
        </>
      )}
    </>
  );
}
