import React, { useEffect, useState } from "react";
import { appendScript } from "../../utils/appendScript";

// import particlesJS from '../../assets/js/order_nftclub-particles.js';

import { Link, useNavigate, useMatch, useResolvedPath } from "react-router-dom";
import ModalNftRegalo from "../POP-ups/ModalNftRegalo";
import ModalWarn from "../POP-ups/ModalWarn";
import ParcticleBackground from "../../utils/parcticleBackground";
import { ModalInfo } from "../POP-ups/ModalInfo";
import ananda_1 from "../../assets/img/ananda-1.png";
import ananda_2 from "../../assets/img/ananda-2.png";
import token_1 from "../../assets/img/token-1.png";
import token_2 from "../../assets/img/token-2.png";
import logo_ananda from "../../assets/img/logo-ananda.png";

const NavLink = (props) => {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return <Link {...props} className={match ? "active" : "non-active"} />;
};

const Home = () => {
  const [searchBeneficiario, setSeachBeneficiario] = useState(null);
  const [modalNftRegalo, setModalNftRegalo] = useState(false);
  const [modalWarn, setModalWarn] = useState(false);
  const [modalInfo, setModalInfo] = useState({ state: false, message: "" });
  const [mailData, setMailData] = useState({
    fullName: "",
    email: "info@novoos.co, Alvaro@novoos.co",
    nftGift: "",
    message: "",
    wallet: "",
    notification: "regalo_novoos",
    correo: "",
  });

  // useEffect(() => {
  //   appendScript(
  //     "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"
  //   );
  // });

  // GET GIFT
  const schema = "ORDER";
  const coleccion = "0x7e68F0cbfa0B65F6d604318A2dA39049De61717a";

  useEffect(() => {
    // const coleccion="0x54e870a4EbA0Cd710899E5c0fAe0Ffd5a42c82dF"
    fetch(
      `https://us-central1-admin-novoos.cloudfunctions.net/app/api/NFTsImages/${schema}/${coleccion}`
    )
      .then((response) => response.json())
      .then((data) => setSeachBeneficiario(data))
      .catch((error) => console.error(error));
  }, [schema, coleccion]);
  const [data, setData] = useState({
    wallet_address: "",
    claim_code: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!data.wallet_address || !data.claim_code) {
      return;
    }
    //buscar si el codigo ya fue registrado
    const array = Object.values(searchBeneficiario);
    const codigoNoReclamado = array.filter(
      (item) => item.code === data.claim_code && item.gift === true
    );
    console.log(codigoNoReclamado);
    if (codigoNoReclamado.length > 0) {
      setModalInfo({ state: true, message: "Codigo de regalo ya reclamado" });
      //window.alert("Codigo de regalo ya reclamado");
    } else {
      setMailData({
        ...mailData,
        wallet: data.wallet_address,
        message: data.claim_code,
      });
      await updateImageGift();
      await searchCodeBeneficiario();

      setData({
        ...data,
        wallet_address: "",
        claim_code: "",
      });
    }
  };

  const updateImageGift = () => {
    if (searchBeneficiario && Object.keys(searchBeneficiario).length > 0) {
      const array = Object.values(searchBeneficiario);
      // const arrayBenefiario = array.filter(item => item.code === giftCode);

      const arrayBenefiario = array.filter((item) => {
        if (item.code === data.claim_code) {
          item.gift = true;
          item.accountBeneficiario = data.wallet_address;
        }
        return item;
      });

      let updatedData = Object.assign({}, arrayBenefiario);
      // const coleccion="0x54e870a4EbA0Cd710899E5c0fAe0Ffd5a42c82dF"
      fetch(
        `https://us-central1-admin-novoos.cloudfunctions.net/app/api/NFTsImages/${schema}/${coleccion}`,
        {
          method: "PUT",
          body: JSON.stringify(updatedData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.error(error));
    }
  };
  const updateBeneficiario = (beneficiarioEncontrado) => {
    if (
      beneficiarioEncontrado &&
      Object.keys(beneficiarioEncontrado).length > 0
    ) {
      const id = beneficiarioEncontrado.id;
      const code = beneficiarioEncontrado.nft_regalo.nft_regalo_codigo;
      const newData = {
        nft_regalo: {
          nft_regalo_codigo: code,
          nft_regalo_estado: 2,
          nft_regalo_account: data.wallet_address,
        },
      };
      //   console.log(newData)
      setModalNftRegalo(true);
      //   alert('¡Felicitaciones Delegado! \n Recuerda que recibiras tu NFT en un plazo máximo de 48 horas hábiles, \n lo enviaremos a la direccion que indicaste.')

      let updatedData = Object.assign({}, newData);
      fetch(
        `https://us-central1-admin-novoos.cloudfunctions.net/app/api/beneficiarios/${schema}/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(updatedData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.error(error));
    }
  };

  const searchCodeBeneficiario = () => {
    fetch(
      `https://us-central1-admin-novoos.cloudfunctions.net/app/api/beneficiario/${schema}`
    )
      .then((response) => response.json())
      .then((datos) => {
        const beneficiarioEncontrado = datos.find(
          (item) => item.nft_regalo.nft_regalo_codigo === data.claim_code
        );
        if (beneficiarioEncontrado) {
          // setSearchCode(beneficiarioEncontrado)
          updateBeneficiario(beneficiarioEncontrado);
        } else {
          setModalWarn(true);
          //   alert(`!Código Invalido \n Ooops, algo salio mal, Revisa que el código sea correcto \n o comunicate con nuestra area de servicio`);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      {modalNftRegalo && mailData && (
        <ModalNftRegalo
          setModalNftRegalo={setModalNftRegalo}
          mailData={mailData}
        />
      )}
      {modalWarn && <ModalWarn setModalWarn={setModalWarn} />}
      {modalInfo.state && (
        <ModalInfo setModalInfo={setModalInfo} message={modalInfo.message} />
      )}
      {/* HERO */}

      <div id="hero" className="hero home">
        <video
          id="video_hero"
          poster=""
          autoPlay={true}
          loop
          muted
          disablePictureInPicture
          controlsList="nodownload"
          playsInline={true}
        >
          {/* <source src="assets/videos/VIDEO_SinFinal.mp4"/> */}
          <source src="https://firebasestorage.googleapis.com/v0/b/ananda-hotel.appspot.com/o/Landing%2FIMG_9842%202.mp4?alt=media&token=68e17f95-a3fa-48a0-8026-784bac597515" />
          {/* <source src="https://firebasestorage.googleapis.com/v0/b/nft-collection-e23b5.appspot.com/o/Nuevo%20Dise%C3%B1o%2Fvideos%2FVIDEO_SinFinal.mp4?alt=media&token=d0e22805-d216-4c4a-aec0-cd38e179ba21" /> */}
        </video>
        <div className="container p-10">
          <h1>
            ¡UN PARAÍSO EN LA
            <br /> CIUDAD AMURALLADA!
          </h1>
          <div className="ocultar">
            <NavLink to="/conviertete-en-delegado">
              <span href="#" className="ppal_cta ">
                ¡COMPRAR TOKEN!
              </span>
            </NavLink>
          </div>
        </div>
      </div>
      {/* VIDEO WELCOME */}
      {/* #video_welcome en esa clase esta la imagen de fondo */}
      <div id="video_welcome" className="video_welcome">
        <div className="container d-flex flex-wrap justify-content-center">
          <div className="video_section">
            <video
              id="video_welcome-video"
              poster="https://firebasestorage.googleapis.com/v0/b/ananda-hotel.appspot.com/o/Landing%2Fposter_video_ananda.png?alt=media&token=f38687d7-7bc1-4151-95c5-3806c55df136"
              controls
              loop
              disablePictureInPicture
              controlsList="nodownload"
            >
              {/* <source src="https://firebasestorage.googleapis.com/v0/b/nft-collection-e23b5.appspot.com/o/Nuevo%20Dise%C3%B1o%2Fvideos%2FORDER_NFT_CLUB_CUT_V01.mp4?alt=media&token=ad4ae8c5-d09e-49d0-b4aa-5062fb013fd9" /> */}
              <source src="https://firebasestorage.googleapis.com/v0/b/ananda-hotel.appspot.com/o/Landing%2Fananda.mp4?alt=media&token=7ff33fdd-1084-4125-a272-79ab5cb8e925" />
            </video>
            <div className="ocultar-mobile" style={{ padding: "15px" }}>
              <NavLink to="/conviertete-en-delegado">
                <span href="#" className="ppal_cta">
                  ¡Comprar nft!
                </span>
              </NavLink>
            </div>
            {/* <NavLink to="/checkout">
                                <span href="#" className="ppal_cta mobile_cta">¡Conviertete en uno!</span>
                        </NavLink> */}
            <p>
              ¡Únete a la comunidad más privilegiada de Cartagena! Los miembros
              de <span className="yellow_txt"> "ANANDÁ MEMBERS CLUB" </span>
              serán los propietarios de un
              <span className="yellow_txt">
                {" "}
                Token de membresía de ANANDÁ HOTEL BOUTIQUE{" "}
              </span>
              y tendrán acceso a privilegios dispuestos para entregar valor
              tangible a la comunidad hotelera más exclusiva de la región, estas
              personas podrán acceder libremente a nuestras instalaciones,
              disfrutar de descuentos en nuestro restaurante vedana y gozar de
              un trato preferencial con tarifas especiales en cada una de sus
              visitas al mejor hotel de cartagena.
              {/* <span className="yellow_txt"> hotel de Cartagena</span>. */}
            </p>
          </div>{" "}
          {/* end video section */}
          <div id="our_history_section" style={{ marginBottom: "80px" }}>
            <h2 style={{ color: "#BB3631" }}>Sobre Anandá…</h2>
            <p>
              ANANDÁ HOTEL BOUTIQUE está ubicado en la{" "}
              <span className="yellow_txt" style={{ color: "#BB3631" }}>
                ciudad amurallada de Cartagena
              </span>
              , contamos con habitaciones de lujo, un restaurante con cocina de
              autor y una terraza espectacular para disfrutar de los atardeceres
              en el Jacuzzi.
            </p>

            {/* imagenes de muestra  */}
            {/* <div className="our_history_images d-flex justify-content-around">
              <img
                style={{ maxHeight: "500px" }}
                src={ananda_1}
                className="img-fluid float-start"
                alt="Order NFT Club ficcion"
              />
              <img
                style={{ maxHeight: "500px" }}
                src={ananda_2}
                className="img-fluid float-end"
                alt="Order NFT Club realidad"
              />
            </div> */}
          </div>
        </div>
      </div>
      {/* BENEFICTS SECTION  */}
      <div id="benefics_section" className="benefics_section">
        <div className="benefics_title">
          {/* <img
            className="benefics_img img-fluid"
            // src="https://firebasestorage.googleapis.com/v0/b/nft-collection-e23b5.appspot.com/o/Nuevo%20Dise%C3%B1o%2F04_BG.webp?alt=media&token=4a65775a-fd7f-4e8d-81c8-f9ae551c7870"
            src="https://firebasestorage.googleapis.com/v0/b/nft-collection-e23b5.appspot.com/o/Nuevo%20Dise%C3%B1o%2Fcaja.webp?alt=media&token=33530c1c-852e-4371-929b-73ab9423e3d6"
            alt="Beneficios delegados"
          /> */}
          <h2>
            Beneficios ANANDÁ
            <br />
            MEMBERS CLUB
          </h2>
        </div>

        <div className="benefics_content py-1">
          <div className="container align-items-start py-2 d-flex">
            <div id="beneficios" className="benefics_info1 col-6">
              <div className="inner-benefics">
                {/* <h3 style={{color:'#42210B'}}> */}
                <h3 style={{ color: "#42210B" }}>
                  Beneficios de <br />
                  <span className="yellow_txt" style={{ color: "#BB3631" }}>
                    DE LA COMUNIDAD
                  </span>
                </h3>

                <div className="accordion" id="beneficios_ordinarios_acc">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="beneficio1">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse1"
                        aria-expanded="true"
                        aria-controls="collapse1"
                      >
                        1. Trato preferencial.
                      </button>
                    </h2>
                    <div
                      id="collapse1"
                      className="accordion-collapse collapse show"
                      aria-labelledby="beneficio1"
                      data-bs-parent="#beneficios_ordinarios_acc"
                    >
                      <div className="accordion-body">
                        <p>- Recibimiento VIP personalizado</p>
                        <p>
                          - Cortesía de la casa (Bienvenida) → ½ botella de vino
                          (u otro licor) y entrada para picar.
                        </p>
                        <p>
                          - Servicio de transporte desde y hacia el aeropuerto.
                        </p>
                        <p>
                          - Servicio de conductor gratuito (2 trayectos
                          programados).
                        </p>
                        <p>
                          - Acceso a un lounge exclusivo con bebidas y
                          aperitivos gratuitos.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading2">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapse2"
                      >
                        2. Upgrades de habitación.
                      </button>
                    </h2>
                    <div
                      id="collapse2"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading2"
                      data-bs-parent="#beneficios_ordinarios_acc"
                    >
                      <div className="accordion-body">
                        <p>
                          Según la ocupación del hotel, los miembros del club
                          podrán acceder a habitaciones de categoría superior
                          sin excedentes en el costo de su reserva.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading3">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseT3"
                        aria-expanded="false"
                        aria-controls="collapseT3"
                      >
                        3. Acceso gratuito a planes de pasadias en el hotel
                        (Consumo mínimo).
                      </button>
                    </h2>
                    <div
                      id="collapseT3"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading3"
                      data-bs-parent="#beneficios_ordinarios_acc"
                    >
                      <div className="accordion-body">
                        <p>
                          Los miembros del club podrán acceder con sus
                          acompañantes a las instalaciones de ANANDÁ HOTEL
                          BOUTIQUE de forma gratuita, teniendo en cuenta que
                          deben cumplir con un valor de consumo mínimo por
                          persona .
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading4">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapse4"
                      >
                        4. Alojamientos con tarifa preferencial (Descuentos del
                        20% al 60% según temporada).
                      </button>
                    </h2>
                    <div
                      id="collapse4"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading4"
                      data-bs-parent="#beneficios_ordinarios_acc"
                    >
                      <div className="accordion-body">
                        <p>
                          - XX% de descuento en alojamiento para estadías de una
                          sola noche.
                        </p>
                        <p>
                          - XX% de descuento en alojamiento para estadías de 2
                          noches.
                        </p>
                        <p>
                          - XX% de descuento en alojamiento para estadías de 3
                          noches o más.
                        </p>
                        <p>
                          - XX% de descuento en la reserva de una segunda
                          habitación.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading5">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5"
                      >
                        5. 20% de descuento en el restaurante del hotel.
                      </button>
                    </h2>
                    <div
                      id="collapse5"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading5"
                      data-bs-parent="#beneficios_ordinarios_acc"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          - Los dueños de un Token de membresía podrán acceder a
                          descuentos permanentes del 20% en el restaurante
                          Vedana, estén o no hospedados en el hotel.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading6">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                      >
                        6. Acceso a un menú de autor exclusivo para nuestro
                        Members Club.
                      </button>
                    </h2>
                    <div
                      id="collapse6"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading6"
                      data-bs-parent="#beneficios_ordinarios_acc"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          - Hemos seleccionado cuidadosamente cada uno de los
                          platillos que podrás disfrutar durante tus estadías en
                          en hotel o tus visitas al restaurante vedana, recuerda
                          que estos sabores solo podrán ser degustados por
                          aquellos que hagan parte de ANANDA MEMBERS CLUB.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading7">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapse7"
                      >
                        7. Acceso a un menú de autor exclusivo para nuestro Club
                        NFT.
                      </button>
                    </h2>
                    <div
                      id="collapse7"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading7"
                      data-bs-parent="#beneficios_ordinarios_acc"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          Hemos seleccionado cuidadosamente cada uno de los
                          platillos que podrás disfrutar durante tus estadías en
                          en hotel o tus visitas al restaurante Vedana, recuerda
                          que estos sabores solo podrán ser degustados por
                          aquellos que hagan parte de ANANDÁ NFT CLUB.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading8">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse8"
                        aria-expanded="false"
                        aria-controls="collapse8"
                      >
                        8. Servicio de Spa incluido en tu reserva.
                      </button>
                    </h2>
                    <div
                      id="collapse8"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading8"
                      data-bs-parent="#beneficios_ordinarios_acc"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          Disfruta de la relajación sin pensar en un costo
                          extra, perfecto para viajes de negocios o una escapada
                          romántica con tu pareja.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="tokens" className="benefics_info2 col-6">
              <div className="inner-benefics">
                <h3 className="tokens">Tokens</h3>
                {/* src="https://firebasestorage.googleapis.com/v0/b/nft-collection-e23b5.appspot.com/o/Nuevo%20Dise%C3%B1o%2FORDER_NFT_Colleccion_045.gif?alt=media&token=f855adce-9d79-43d8-96c5-2c6d44864f55" */}
                <img
                  className="benefics-imgs img-fluid p-5"
                  src={ananda_1}
                  alt="Token"
                />
                <h4 className="delegados">$650 USD</h4>
                {/* corregir para que estilos y se use los adecuados y no de a */}
                {/* <NavLink to="/conviertete-en-delegado" style={{display:"flex", justifyContent:"center"}}>
                  <span href="#" className="cta_small-btn">
                    ¡Conviertete en uno!
                  </span>
                </NavLink> */}
                <a href="/conviertete-en-delegado" className="cta_small-btn">
                  ¡COMPRAR TOKEN!
                </a>
                {/* <img class="benefics-imgs img-fluid p-5" src="assets/img/ORDER_NFTGOLD_001.gif" /> */}
              </div>
            </div>

            {/* formulario llevar nft de regalo */}

            <div className="benefics_info1 col-6" id="regalo">
              <div className="inner-benefics">
                <div className="nft-gift">
                  <div>
                    <h3>
                      <span
                        className="nft-gift-inner-title1"
                        style={{ color: "#42210B" }}
                      >
                        Lleva un TOKEN de
                      </span>
                      <span className="nft-gift-inner-title2">PRUEBA</span>
                    </h3>
                  </div>

                  <form id="get_a_NFT_gift" onSubmit={handleSubmit}>
                    <input
                      id="wallet_address"
                      className="get_a-NFT-input"
                      type-="text"
                      name="wallet_address"
                      value={data.wallet_address}
                      onChange={handleChange}
                      placeholder="Dirección Wallet"
                      required
                    />
                    <input
                      id="claim_code"
                      className="get_a-NFT-input"
                      type="text"
                      name="claim_code"
                      value={data.claim_code}
                      onChange={handleChange}
                      placeholder="Código regalo"
                      required
                    />
                    <button type="submit" className="get_a-NFT-btn">
                      Reclamar NFT
                    </button>
                  </form>

                  {/* <a href="/testNFT" className="get_a_test-NFT-btn ppal_cta">
                    ¡Lleva un NFT de prueba!
                  </a> */}

                  {/* <span
                      href="#"
                      className="get_a_test-NFT-btn ppal_cta mobile_text"
                    >
                      ¡Lleva un NFT de prueba!
                    </span> */}
                </div>
              </div>
            </div>
            <div id="tokenss" className="benefics_info2 col-6">
              <div className="inner-benefics">
                <img
                  className="benefics-imgs img-fluid p-5"
                  src={token_2}
                  alt="NFT golden"
                />
              </div>
            </div>
          </div>
        </div>

        {/* FAQS SECTION */}
        <div id="faqs" className="faqs">
          <h2 className="faq-title">Faqs</h2>
          <div className="faq-body">
            <div className="accordion" id="faqs_order_acc">
              <div className="accordion-item">
                <h2 className="accordion-header" id="faq1">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFaq1"
                    aria-expanded="true"
                    aria-controls="collapseFaq1"
                  >
                    1.¿Cuántos TOKENS de ANANDÁ HOTEL BOUTIQUE existen?
                  </button>
                </h2>
                <div
                  id="collapseFaq1"
                  className="accordion-collapse collapse show"
                  aria-labelledby="faq1"
                  data-bs-parent="#faqs_order_acc"
                >
                  <div className="accordion-body">
                    <p>
                      ANANDA MEMBERS CLUB emitió una colección con 100 TOKENS,
                      estos son un contrato inteligente con un código de
                      identificación único integrado a una ilustración alusiva a
                      Cartagena.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faq2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFaq2"
                    aria-expanded="false"
                    aria-controls="collapseFaq2"
                  >
                    2. ¿Cómo puedo comprar un TOKEN del ANANDÁ MEMBERS CLUB?
                  </button>
                </h2>
                <div
                  id="collapseFaq2"
                  className="accordion-collapse collapse"
                  aria-labelledby="faq2"
                  data-bs-parent="#faqs_order_acc"
                >
                  <div className="accordion-body">
                    <p>
                      La venta inicial de los TEKENS de ANANDÁ MEMBERS CLUB se
                      realizará en la página principal ingresando en el botón de
                      compra de la colección, previamente deberás crear una
                      CryptoWallet como Metamask para almacenarlo y darle el uso
                      que desees.
                    </p>
                    <p>
                      En caso de no poder comprarlo en las etapas de su venta
                      inicial, podrás acceder a uno de ellos en el mercado
                      secundario por medio de un marketplace dispuesto para
                      ello.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faq3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFaq3"
                    aria-expanded="false"
                    aria-controls="collapseFaq3"
                  >
                    3. ¿Que es una CryptoWallet?
                  </button>
                </h2>
                <div
                  id="collapseFaq3"
                  className="accordion-collapse collapse"
                  aria-labelledby="faq3"
                  data-bs-parent="#faqs_order_acc"
                >
                  <div className="accordion-body">
                    <p>
                      Las CryptoWallet son el puente que nos permite administrar
                      nuestros activos digitales en la blockchain, aquí
                      almacenamos tanto nuestras CryptoMonedas como nuestros
                      TOKENS.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faq4">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFaq4"
                    aria-expanded="false"
                    aria-controls="collapseFaq4"
                  >
                    4. ¿Cómo crear una CryptoWallet?
                  </button>
                </h2>
                <div
                  id="collapseFaq4"
                  className="accordion-collapse collapse"
                  aria-labelledby="faq4"
                  data-bs-parent="#faqs_order_acc"
                >
                  <div className="accordion-body">
                    <p>
                      ¡No te preocupes! Hemos realizado un video tutorial sobre
                      cómo crear una CryptoWallet para ti, este se encuentra en
                      la sección de compra y será enviado junto con las
                      instrucciones para poder recibir tu TOKEN a tu correo
                      electrónico una vez haya terminado la preventa pública.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faq5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFaq5"
                    aria-expanded="false"
                    aria-controls="collapseFaq4"
                  >
                    5. ¿Cuándo será la venta inicial?
                  </button>
                </h2>
                <div
                  id="collapseFaq5"
                  className="accordion-collapse collapse"
                  aria-labelledby="faq5"
                  data-bs-parent="#faqs_order_acc"
                >
                  <div className="accordion-body">
                    <p>
                      La venta inicial de los TOKENS de ANANDÁ MEMBERS CLUB se
                      realizará durante los días xxxxxxxxxxx.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faq6">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFaq6"
                    aria-expanded="false"
                    aria-controls="collapseFaq6"
                  >
                    6. ¿Cómo puedo recibir mi TOKEN?
                  </button>
                </h2>
                <div
                  id="collapseFaq6"
                  className="accordion-collapse collapse"
                  aria-labelledby="faq6"
                  data-bs-parent="#faqs_order_acc"
                >
                  <div className="accordion-body">
                    <p>
                      Después de crear tu CryptoWallet, deberás copiar el link
                      de su “dirección” y diligenciarlo en el formulario de
                      compra para que podamos realizar el envío.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faq7">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFaq7"
                    aria-expanded="false"
                    aria-controls="collapseFaq7"
                  >
                    7. ¿Cuándo vence mi TOKEN de ANANDÁ MEMBERS CLUB?
                  </button>
                </h2>
                <div
                  id="collapseFaq7"
                  className="accordion-collapse collapse"
                  aria-labelledby="faq7"
                  data-bs-parent="#faqs_order_acc"
                >
                  <div className="accordion-body">
                    <p>
                      Nunca. Los tokens emitidos por ANANDÁ NFT CLUB serán
                      efectivos durante la vida útil del proyecto.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faq8">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFaq8"
                    aria-expanded="false"
                    aria-controls="collapseFaq8"
                  >
                    8. ¿Puedo revender mi TOKEN del ANANDÁ MEMBERS CLUB?
                  </button>
                </h2>
                <div
                  id="collapseFaq8"
                  className="accordion-collapse collapse"
                  aria-labelledby="faq8"
                  data-bs-parent="#faqs_order_acc"
                >
                  <div className="accordion-body">
                    <p>
                      Si, cada poseedor de un TOKEN de ANANDÁ CLUB podrá
                      revenderlo en NOVOOS MARKETPLACE. Cabe aclarar, que cada
                      vez que un NFT sea revendido ANANDA HOTEL BOUTIQUE
                      recibirá una comisión del 10% de su valor.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faq9">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFaq9"
                    aria-expanded="false"
                    aria-controls="collapseFaq9"
                  >
                    9. ¿Cual es nuestro propósito?
                  </button>
                </h2>
                <div
                  id="collapseFaq9"
                  className="accordion-collapse collapse"
                  aria-labelledby="faq9"
                  data-bs-parent="#faqs_order_acc"
                >
                  <div className="accordion-body">
                    <p>
                      Queremos fortalecer nuestra relación con la comunidad
                      Anandá. Por eso, creamos un espacio en donde nuestros
                      clientes podrán disfrutar de beneficios exclusivos para
                      ellos y monetizar su inversión acorde a nuestro
                      crecimiento.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faq10">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFaq10"
                    aria-expanded="false"
                    aria-controls="collapseFaq10"
                  >
                    10.¿Qué derechos estoy adquiriendo sobre dicho activo al
                    realizar la compra?
                  </button>
                </h2>
                <div
                  id="collapseFaq10"
                  className="accordion-collapse collapse"
                  aria-labelledby="faq10"
                  data-bs-parent="#faqs_order_acc"
                >
                  <div className="accordion-body">
                    <p>
                      Al comprar un TOKEN de ANANDÁ MEMBERS CLUB está adquiriendo
                      los derechos patrimoniales sobre el mismo, esto quiere
                      decir que este activo será de su propiedad y podrá
                      realizar lo que desee con él; excepto modificarlo y/o
                      replicarlo para su distribución.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="accordion-item">
              <h2 className="accordion-header" id="faq11">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFaq11"
                  aria-expanded="false"
                  aria-controls="collapseFaq11"
                >
                  11. ¿Qué derechos estoy adquiriendo sobre dicho activo al
                  realizar la compra?
                </button>
              </h2>
              <div
                id="collapseFaq11"
                className="accordion-collapse collapse"
                aria-labelledby="faq11"
                data-bs-parent="#faqs_order_acc"
              >
                <div className="accordion-body">
                  <p>
                    Al comprar un NFT de ANANDÁ NFT CLUB está adquiriendo los
                    derechos patrimoniales sobre el mismo, esto quiere decir que
                    este activo será de su propiedad y podrá realizar lo que
                    desee con él; excepto modificarlo y/o replicarlo para su
                    distribución.
                  </p>
                </div>
              </div>
            </div> */}
            </div>
          </div>

          <h2 className="yoyknow">¿YA CONOCES NUESTROS HOTEL?</h2>
          <div className="goto-storeBox">
            <img
              src={logo_ananda}
              alt="Order Comics Logo"
              className="img-fluid oc_whitebrand"
            />
            <h3>Anandá Hotel </h3>
            <a
              href="https://www.instagram.com/hotelanandacartagena/"
              className="goto_store"
              target="_blank"
              rel="noreferrer"
            >
              INSTAGRAM
            </a>
          </div>
        </div>
      </div>

      {/* ROADMAP SECTION */}
      {/* <div id="roadmap" className="roadmap_section">
        <ParcticleBackground />
        <h2 className="roadmap_title_section">Roadmap</h2>
        <div className="roadmap_timeline_wrapper">
          <div className="roadmap_card">
            <div className="roadmap_card-title">
              <h4>Jul 2023</h4>
            </div>
            <div className="roadmap_card-info">
              <p>Activación de descuentos del 25% en productos ORDER CÓMICS.</p>
            </div>
          </div>
          <div className="roadmap_card">
            <div className="roadmap_card-title">
              <h4>Sep 2023</h4>
            </div>
            <div className="roadmap_card-info">
              <p>
                Acceso a preventas privadas de productos y nuevos lanzamientos.
              </p>
            </div>
          </div>
          <div className="roadmap_card">
            <div className="roadmap_card-title">
              <h4>Sep 2023</h4>
            </div>
            <div className="roadmap_card-info">
              <p>
                Reservas y/o descuentos en boletería para eventos en los que
                ORDER CÓMICS tenga presencia.
              </p>
            </div>
          </div>
          <div className="roadmap_card">
            <div className="roadmap_card-title">
              <h4>Oct 2023</h4>
            </div>
            <div className="roadmap_card-info">
              <p>Acceso a mercancía única y exclusiva.</p>
            </div>
          </div>
          <div className="roadmap_card">
            <div className="roadmap_card-title">
              <h4>2024</h4>
            </div>
            <div className="roadmap_card-info">
              <p>Derecho al voto para influir en la toma de decisiones.</p>
            </div>
          </div>
          <div className="roadmap_card">
            <div className="roadmap_card-title">
              <h4>2024</h4>
            </div>
            <div className="roadmap_card-info">
              <p>
                Producción de una serie animada basada en ORDER, dicha
                planificación tiene lugar en el momento que estés leyendo esto.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
